@import "fonts";
@import "colors";

@mixin cleanButton() {
  background-color: transparent;
  border: 0; }
@mixin cleanLink() {
  text-decoration: none;
  cursor: pointer; }
@mixin cleanList() {
  list-style: none;
  padding: 0;
  margin: 0; }
@mixin cleanField {
  border: 0;
  background-color: transparent; }
@mixin hoverZoom($duration,$to) {
  transition-duration: $duration;
  transform: scale($to,$to); }
@mixin flex($justify-content, $align-items, $align-content) {
  display: flex;
  @if ($justify-content == '') {
    justify-content: flex-start; }
  @else {
    justify-content: $justify-content; }
  @if ($align-items == '') {
    align-items: flex-start; }
  @else {
    align-items: $align-items; }
  @if ($align-content == '') {
    align-content: flex-start; }
  @else {
    align-content: $align-content; } }
@mixin createEmptyPseudoElement($top, $left, $width, $height) {
  position: absolute;
  content: '';
  @if ($top == '') {
    top: 0; }
  @else {
    top: $top; }
  @if ($left == '') {
    left: 0; }
  @else {
    left: $left; }
  @if ($width == '') {
    width: 100%; }
  @else {
    width: $width; }
  @if ($height == '') {
    height: 1px; }
  @else {
    height: $height; } }

@function text-stroke($size: 2, $color: #fff, $correction: 0) {
  $radius: $size - $correction;
  $stroke: ();
  @for $i from -$size through $size {
    @for $k from -$size through $size {
      $x: $k;
      $y: $i;
      @if $k > 0 {
        $x: $k - 0.5; }
      @elseif $k < 0 {
        $x: $k + 0.5; }
      @if $i > 0 {
        $y: $i - 0.5; }
      @elseif $i < 0 {
        $y: $i + 0.5; }
      @if ($x*$x + $y*$y <= $radius*$radius) {
        $stroke: append($stroke, $i*1px $k*1px 0 $color, comma); } } }
  @return $stroke; }

@mixin text-stroke($size: 2, $color: #000, $correction: 0) {
  text-shadow: text-stroke($size, $color, $correction); }
