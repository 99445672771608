.ComingSoon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    pointer-events: none;
  }
  &_content {
    z-index: 1;
    &_title {
      text-align: center;
      color: #f16e5e;
      text-shadow: 0 0 10px #ffffff;
      font-size: 3em;
      span {
        display: block;
      }
    }

    .button-brill__middle-part {
      width: calc(100% - 19px)!important;
    }

    button {
      margin: 0 auto;
      min-width: 211px;
      text-transform: uppercase;
      color: #29B6F6;
      font-weight: 500;
      font-size: 15px;
    }
  }
}
