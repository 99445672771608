@import "../colors";

$color__date-message: #b1b5c1;

$color__help-text: $colorSilver;

$color__text-message: #fff;

$color__author-message: #959595;

$bg__controls-message: #fff;

$colorUpdateMessage: $colorLightYellow;

$colorMention: $colorMainHover;
