@import '../fonts/icomoon/style.css';
@import 'themes/index';
@import './typography';

input, button, textarea, div {
  outline: 0; }

html {
  font-family: $fontBasic, sans-serif; }
html, body, #root, main {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
body {
  background-color: $colorMagnesium; }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: transparent;
  transition: background-color 5000s ease-in-out 0s !important; }
.row-group {
  margin-bottom: 10px; }
.row {
  width: 100%; }
.row-flex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; }
.flex-row {
  display: flex; }
.hash-link {
  &:before {
    content: '# ';
    color: $colorMain;
    font-size: 14px; } }
.hash {
  &:before {
    content: '# ';
    color: $colorMain; } }
.text-align {
  &--center {
    text-align: center; }
  &--left {
    text-align: left; }
  &--righ {
    text-align: right; }
  &--justify {
    text-align: justify; } }
.text-size {
  &--xs {
    font-size: 12px; }
  &--sm {
    font-size: 14px; }
  &--md {
    font-size: 16px; }
  &--lg {
    font-size: 18px; } }
.link {
  color: $colorLightBlue;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline; } }
.title {
  &--xs {
    font-size: 16px; }
  &--sm {
    font-size: 18px; }
  &--md {
    font-size: 22px; }
  &--lg {
    font-size: 26px; }
  &--up {
    font-size: 32px; }
  &--bg {
    font-size: 36px; } }
.weight {
  &--light {
    font-weight: 300; }
  &--normal {
    font-weight: 400; }
  &--medium {
    font-weight: 500; }
  &--semi-bold {
    font-weight: 600; }
  &--bold {
    font-weight: 700; }
  &--extra {
    font-weight: 900; } }
.button-group {
  width: 100%; }
.button {
  &--xs {
    font-size: 12px;
    height: 32px; }
  &--sm {
    font-size: 14px;
    height: 36px; }
  &--md {
    font-size: 16px;
    height: 46px; }
  &--lg {
    font-size: 16px;
    height: 48px; }
  &--bg {
    font-size: 18px;
    height: 52px; }
  &--fill {
    width: 100%; }
  &--cancel {
    @include  cleanButton;
    text-transform: uppercase;
    margin-left: 16px;
    cursor: pointer;
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
    color: #aab7b8;
    &:hover {
      color: $colorLightBlue; } } }
// signIn
.bg {
  &--green {
    background-color: $colorGreen; }
  &--yellow {
    background-color: $colorAmber; }
  &--turquoise {
    background-color: $colorLightBlue; }
  &--orange {
    background-color: $colorMagnesium; }
  &--lime {
    background-color: $colorLightGreen; } }
.display {
  &--inline-block {
    display: inline-block; }
  &--block {
    display: block; } }
.text--cut {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
.text--cut .channel-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }
.title-page {
  color: $colorLead;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center; }
.text--breaker {
  word-break: break-word; }
svg, path {
  vector-effect: non-scaling-stroke; }
.emoji-mart-emoji {
  font-size: 3px;
  color: rgba(0,0,0,0);
  user-select: contain; }
.string--reply {
  width: 100%;
  box-sizing: border-box;
  display: inline-block;
  word-break: break-word;
  border-left: 2px solid $colorSilver;
  border-radius: 2px;
  margin: 4px 0;
  padding: 4px 8px;
  font-size: 12px;
  background-color: $colorMagnesium; }
pre {
  word-break: break-word;
  white-space: normal;
  border-radius: 2px;
  padding: 4px;
  box-sizing: border-box;
  background-color: $colorMagnesium;
  border: 1px solid  $colorSilver; }
code {
  box-sizing: border-box;
  display: inline-block;
  word-break: break-word;
  border-radius: 2px;
  margin: 0 2px;
  padding: 0 4px;
  font-size: 11px;
  font-family: monospace;
  border: 1px solid $colorMagnesiumDarker;
  background-color: $colorCarbon;
  color: #e01e5a;
  white-space: pre; }
.canvas-element {
  display: none; }
.help-cursor {
  cursor: help; }

//
.header-nav {
  &__link {
    position: relative;
    @include cleanLink;
    cursor: pointer;
    white-space: nowrap;
    padding: 0 16px;
    margin-right: 2px;
    font-size: 14px;
    height: 32px;
    font-weight: 400;
    color: #545B64;
    &:not(.header-nav__link--disabled):hover {
      &::after {
        width: 100%; } }
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      margin-top: 1px;
      left: 0;
      width: 0;
      height: 2px;
      transition-duration: .25s;
      background-color: #545B64; }
    &--active {
      color: $colorMain;
      &::after {
        width: 100%;
        background-color: $colorMain; } }
    &--disabled {
      color: $colorSilver; } } }
.xs-hidden[class] {
  @include if-xs {
    display: none; } }
