//@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i")
//@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,700i,900,900i")

$fontBasic: 'Roboto';
$fontButtons: 'Roboto';
$fontLinks: 'Roboto';
$fontTexts: 'Roboto';
$fontTitles: 'Roboto';
$fontSupportInfo: 'Roboto';

$fontIcons: 'icomoon';
